import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DocumentElement } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { Observable, Subject, fromEvent } from 'rxjs';
import { debounceTime, startWith, takeUntil } from 'rxjs/operators';
import { ComposerService } from '../../composer.service';
import { ComposerToolbarService } from '../composer-toolbar.service';
import { PROPERTY_MAP } from './composer-toolbar-properties';
import { Store } from '@ngrx/store';
import { ShowcasesActions, ShowcasesSelectors } from 'src/app/showcases/showcases-store';
import { ComposerFrameLayoutService } from '../../composer-frame/composer-frame-layout/composer-frame-layout.service';
import { EditorMode } from '@common/editor-mode/editor-mode-store/editor-mode.state';
import { RootStoreState } from '@rootstore';
import { SearchReplaceActions } from '@common/search-replace/search-replace-store';
import { EditorModeSelectors } from '@common/editor-mode/editor-mode-store';
import { DocumentHistorySelectors } from '@common/document-history/document-history-store';
import { ConfirmationBoxService } from '@components/confirmation-box/confirmation-box';
import { ComposerSnapshotService } from '../../composer-snapshot/composer-snapshot.service';
import { ComposerGenerateCanvasFramesService } from '../../composer-frame/composer-generate-canvas-frames/composer-generate-canvas-frames.service';
import { DocumentComponentService } from 'src/app/presentation/document/document-component/document-component-service';
import { FeatureFlagsSelectors } from '@common/feature-flags';
import { Feature } from '@common/feature-flags/feature-flag';
import { DocumentSelectors } from 'src/app/presentation/document/document-store';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { SideMenuOverlay } from 'src/app/presentation/document/document-store/document.state';
import { ChooserSourceOptionTypes } from '@common/item-data-chooser/source-option';

@Component({
  selector: 'app-composer-toolbar',
  templateUrl: './composer-toolbar.component.html',
  styleUrls: ['./composer-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComposerToolbarComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('composerToolbar') toolBar: ElementRef;

  public defaultProperty = 'select';
  public defaultProperties: string[] = PROPERTY_MAP.select.properties;
  public properties: string[];
  public elements: DocumentElement[];
  public elementType: string;
  public isLocked = false;
  public viewSize$: Observable<any>;

  private subject: Subject<string> = new Subject();
  // Frame Types | more types | iframe, showroom
  public isDocumentFrame = false;
  public isCollectionFrame = false;
  public isGridFrame = false;
  // ----------------------------
  public textFormat;
  public textElement;
  public annotationType;
  public editorMode: EditorMode;
  public frameType: string;
  public entitySnapshot$: Observable<any>;
  public dashboardFeatureFlag = false;
  public promoteFeatureFlag = false;
  public libraryFeatureFlag = false;
  public toggleChooser = false;
  public documentGenerationConfigId = null;

  private destroy$ = new Subject();

  // public layoutSize: string = 'Large';
  // screenSizeMap = new Map([
  //   [Breakpoints.XSmall, 'Small'],
  //   [Breakpoints.Small, 'Small'],
  //   [Breakpoints.Medium, 'Medium'],
  //   [Breakpoints.Large, 'Large'],
  //   [Breakpoints.XLarge, 'Large'],
  // ]);

  public barSize = window.innerWidth;

  get showMoreButton() {
    return (
      this.miniExport ||
      this.miniEditLineBoard ||
      this.miniTextButtons ||
      this.miniComponentButton ||
      this.miniTextStyles ||
      this.miniStickyStyles ||
      this.miniMoreStyles ||
      this.miniNewElement ||
      this.miniChooserPanel
    );
  }

  get miniExport() {
    if (this.isCollectionFrame || this.isGridFrame) {
      return this.barSize < 1000;
    }

    const docBarSize = this.documentGenerationConfigId ? this.barSize - 150 : this.barSize;
    switch (this.elementType) {
      case 'text':
      case 'text_tool':
      case 'sticky_note':
        return docBarSize < 1530;
      case 'component':
      case 'rectangle':
      case 'line':
        return docBarSize < 1250;
      case 'pen':
      case 'highlighter':
      case 'image':
      case 'rectangle':
      case 'star':
      case 'rhombus':
      case 'diamond':
      case 'cloud':
      case 'heart':
      case 'circle':
      case 'svg':
      case 'right_arrow':
      case 'double_arrow':
        return this.barSize < 1200;
      default:
        return docBarSize < 1150;
    }
  }
  get miniEditLineBoard() {
    if (!this.documentGenerationConfigId) {
      return false;
    }
    switch (this.elementType) {
      case 'text':
      case 'text_tool':
      case 'sticky_note':
        return this.barSize < 1600;
      case 'component':
      case 'rectangle':
      case 'line':
        return this.barSize < 1350;
      default:
        return this.barSize < 1250;
    }
  }
  get miniTextButtons() {
    return this.barSize < 950 ? true : false;
  }
  get miniComponentButton() {
    return this.isDocumentFrame && this.elementType === 'component' && !this.isLocked && this.barSize < 1300;
  }
  get miniMoreStyles() {
    if (this.isDocumentFrame && !this.isLocked && !!this.elementType) {
      switch (this.elementType) {
        case 'pen':
        case 'highlighter':
        case 'image':
        case 'rectangle':
        case 'star':
        case 'rhombus':
        case 'diamond':
        case 'cloud':
        case 'heart':
        case 'circle':
        case 'svg':
        case 'right_arrow':
        case 'double_arrow':
        case 'text':
        case 'text_tool':
        case 'sticky_note':
          return this.barSize < 1200;
        default:
          return false;
      }
    } else {
      return false;
    }
  }
  get miniTextStyles() {
    if (this.isDocumentFrame && !this.isLocked) {
      return (
        (this.elementType === 'text' || this.elementType === 'text_tool' || this.elementType === 'sticky_note') &&
        this.barSize < 1490 // The width where text styles begin to collapse
      );
    }
    return false;
  }
  get miniStickyStyles() {
    if (this.isDocumentFrame && !this.isLocked) {
      return this.elementType === 'sticky_note' && this.barSize < 1400;
    }
    return false;
  }
  get miniNewElement() {
    return this.isDocumentFrame && this.barSize < 1050 ? true : false;
  }
  get miniChooserPanel() {
    return this.barSize < 800 ? true : false;
  }

  constructor(
    private cd: ChangeDetectorRef,
    private service: ComposerToolbarService,
    private composerService: ComposerService,
    private composerFrameLayoutService: ComposerFrameLayoutService,
    private composerGenerateCanvasFramesService: ComposerGenerateCanvasFramesService,
    private confirmationBoxService: ConfirmationBoxService,
    private composerSnapshotService: ComposerSnapshotService,
    private documentComponentService: DocumentComponentService,
    private store: Store<RootStoreState.State>,
    // private breakpointObserver: BreakpointObserver,
  ) {
    // this.breakpointObserver.observe([
    //   Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge
    // ]).pipe(takeUntil(this.destroy$)).subscribe(result => {
    //   for (const query of Object.keys(result.breakpoints)) {
    //     if (result.breakpoints[query]) {
    //       this.layoutSize = this.screenSizeMap.get(query) ?? 'Small';
    //     }
    //   }
    // })

    this.init();
    this.store.select(EditorModeSelectors.editorMode).subscribe((mode) => (this.editorMode = mode));
    //TODO: use featureFlagService instead of ngrx store check
    // this.showAssetLibrary$ = this.featureFlagService.getFeatureObservable(Feature.ASSET_LIBRARY);
    this.store
      .select(FeatureFlagsSelectors.featureFlags)
      .pipe(takeUntil(this.destroy$))
      .subscribe((flags) => {
        this.dashboardFeatureFlag = !!flags.find((x) => x.featureName === Feature.DASHBOARD);
        this.promoteFeatureFlag = !!flags.find((x) => x.featureName === Feature.PROMOTE);
        this.libraryFeatureFlag = !!flags.find((x) => x.featureName === Feature.ASSET_LIBRARY);
      });
    this.entitySnapshot$ = this.store.select(DocumentHistorySelectors.currentEntitySnapshot);
    this.viewSize$ = this.store.select(DocumentSelectors.viewSize);
  }

  ngAfterViewInit(): void {}

  // Added debounceTime to prevent numerous sequential updates of values when dragging on the color chooser.
  ngOnInit(): void {
    fromEvent(window, 'resize', { passive: true })
      .pipe(startWith(null), debounceTime(100), takeUntil(this.destroy$))
      .subscribe(() => {
        this.barSize = this.toggleChooser ? window.innerWidth - 348 : window.innerWidth;
        this.cd.detectChanges();
      });

    this.subject.pipe(debounceTime(500)).subscribe((values) => {
      this.updateValues(values);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private init() {
    this.properties = this.defaultProperties;

    this.service.documentElementEvents.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event) {
        if (event.eventType === 'deselect') {
          this.properties = this.defaultProperties;
          this.elementType = 'select';
        } else {
          if (event.selectedElements) {
            this.elements = event.selectedElements;
            this.isLocked = this.elements?.findIndex((e) => e.isLocked) !== -1;
            if (this.elements && this.elements.length > 0) {
              this.elementType = this.getElementType(this.elements);
              this.properties = PROPERTY_MAP[this.elementType]?.properties || [];
            }
          }
        }
        this.cd.detectChanges();
      }
    });

    this.composerService.currentFrame.pipe(takeUntil(this.destroy$)).subscribe((frame) => {
      this.frameType = frame?.type;
      this.properties = this.defaultProperties;
      this.isDocumentFrame = frame?.type === 'document';
      this.isCollectionFrame = frame?.type === 'collection';
      this.isGridFrame = frame?.type === 'grid';
      this.documentGenerationConfigId = frame?.documentGenerationConfigId || null;
    });

    this.service.textElementEvents.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      this.textElement = event.element;
      this.textFormat = event.textFormat;
    });

    this.store
      .select(ShowcasesSelectors.annotationType)
      .pipe(takeUntil(this.destroy$))
      .subscribe((annotationType) => {
        this.annotationType = annotationType;
      });

    this.store
      .select(DocumentSelectors.toggleChooser)
      .pipe(takeUntil(this.destroy$))
      .subscribe((toggleChooser) => {
        this.toggleChooser = toggleChooser?.showChooser;
        this.barSize = this.toggleChooser ? window.innerWidth - 348 : window.innerWidth;
      });
  }

  getElementType(elements: DocumentElement[]) {
    if (elements.length === 0) {
      return this.defaultProperty;
    }

    const firstElementType = elements[0].type;
    const allSameType = elements.every((element) => element.type === firstElementType);

    if (allSameType) {
      const firstElement = elements[0];
      if (firstElement.type === 'text' && firstElement.isTextTool) {
        return 'text_tool';
      }
      return firstElementType;
    }

    return this.defaultProperty;
  }

  handleDelayedValueChange(values) {
    this.subject.next(values);
  }

  async updateValues(values) {
    if (this.elements.some((e) => e.type === 'svg') && values?.style?.backgroundColor) {
      await this.documentComponentService.recolorAndUpdateSVGElements(
        this.elements.filter((e) => e.type === 'svg'),
        values?.style?.backgroundColor,
      );
    }
    const undoChanges = [];
    const changes = (
      values?.style?.backgroundColor ? this.elements.filter((e) => e.type !== 'svg') : this.elements
    ).map((el) => {
      const existingValues = { style: {} };
      Object.keys(values).forEach((key) => {
        if (key === 'style') {
          // nullify style properties that do not exist in the element for undo
          Object.keys(values.style).forEach((styleProp) => {
            existingValues.style[styleProp] =
              el.style && el.style[styleProp] ? ObjectUtil.cloneDeep(el.style[styleProp]) : null;
          });
        } else {
          existingValues[key] = el[key] ? ObjectUtil.cloneDeep(el[key]) : {};
        }
        if (key === 'isLocked') {
          this.isLocked = values[key];
        }
      });
      let undoElement = Object.assign({ id: el.id }, existingValues);
      // Modify the element itself to trigger OnChanges in inner components.
      // This is needed in cases where two elements have initial values as NULL - for example opacity.
      // If one element's opacity is changed to 70, the current element opacity value still stays NULL
      // in the component, and when user switches to another element, the opacity value in the config
      // bar shows 70, because OnChanges wasn't triggered since currentValue stayed NULL
      el = ObjectUtil.mergeDeep(el, values);

      const element = Object.assign({ id: el.id }, ObjectUtil.cloneDeep(values));

      if ((el.isTextTool || el.type === 'sticky_note') && element?.style?.border?.width != null) {
        element.style.border.width = 0;
      }

      undoChanges.push(undoElement);
      return element;
    });
    this.service.handleElementChanges(changes, undoChanges);
  }

  getCurrentValue(index) {
    if (this.elements?.length) {
      return ObjectUtil.getByPath(this.elements[0], index);
    }
  }

  showComponentConfigurator() {
    this.service.showComponentConfigurator();
  }

  isActive() {
    if (this.isDocumentFrame) {
      return this.service.getInteractionMode() === 'select';
    } else {
      return !this.annotationType;
    }
  }

  resetInteractionMode() {
    // setting this to 'root' instead of 'select' since right after updating a selected element, the interactionMode
    // is set to 'select'. The 'root' mode will deselect all elements before setting the mode to 'select' in the SVGDocument.
    if (this.isDocumentFrame) {
      this.service.setPaintFormatAction('root');
    } else {
      this.store.dispatch(ShowcasesActions.setAnnotationType({ annotationType: null }));
    }
  }

  setInteractionMode(mode) {
    this.service.setInteractionMode(mode);
  }

  getInteractionMode() {
    return this.service.getInteractionMode();
  }

  clearFormat(event) {
    const action = {
      element: this.textElement,
      textFormat: {
        type: 'clearFormat',
      },
    };
    this.service.handleTextElementActions(action);
  }

  getTextAttributeValue(att) {
    if (this.textFormat) {
      return this.textFormat[att];
    }
    return null;
  }

  updateTextElement(values) {
    const action = {
      element: this.textElement,
      textFormat: values,
    };
    this.service.handleTextElementActions(action);
  }

  updateStickyNoteElement(values) {
    const action = {
      element: null,
      textFormat: values,
    };
    this.service.handleStickyNoteElementActions(action);
  }

  showCollectionFrameLayout() {
    this.service.showCollectionFrameLayout();
  }

  showGridFrameLayout() {
    this.service.showGridFrameLayout();
  }

  showCollectionFramePreview() {
    this.composerFrameLayoutService.showPreview();
  }

  toggleSearch() {
    this.store.dispatch(SearchReplaceActions.toggleSearch());
  }

  toggleDashboard() {
    this.service.showDashboard();
  }

  async pasteAsNewFrame() {
    const confirm = await this.confirmationBoxService.open(
      'Confirmation',
      'This action will copy and paste this frame to the current version. Do you want to proceed?',
    );
    if (confirm) {
      this.composerSnapshotService.pasteFrameFromSnapshot();
    }
  }

  generateLineboard() {
    this.composerGenerateCanvasFramesService.generateFrames();
  }

  showItemChooser(itemLib = false) {
    this.showChooserOverlay('addProduct', 'Item Chooser', itemLib);
  }
  showColorChooser() {
    this.showChooserOverlay('addColor', 'Color Chooser');
  }
  showAssetChooser() {
    this.showChooserOverlay('addAsset', 'Asset Chooser');
  }
  private showChooserOverlay(slug, label, itemLib = false) {
    const overlay: SideMenuOverlay = {};
    overlay.icon = '';
    overlay.label = label;
    overlay.slug = slug;
    overlay.showChooser = true;
    if (itemLib) {
      overlay.targetSourceType = ChooserSourceOptionTypes.ITEM_LIBRARY;
    }
    this.service.showChooser(overlay);

    this.setInteractionMode('select');
  }

  setZoomScale(scale) {
    this.composerService.sizePositionHandler.setZoomPresets(scale);
  }
  zoomToFit() {
    this.composerService.sizePositionHandler.center();
  }

  prevent($event) {
    $event.preventDefault();
    $event.stopPropagation();
  }

  editLineboardConfig() {
    this.service.showEditLineboardConfig();
  }
}

// const toolbar = [
//   // Default Button Size | 36px
//   // LEFT SIDE -------------------------------
//   ['Add Frame        | plus icon', 'Frame Name       | Input field', 'Edit Name        | Pencil icon'], // Any frameType | 220px | 3 items
//   //divider

//   'Undo Redo        | Icons', // Any frameType | 72px
//   ['Format Paint     | Paint icon', 'Zoom             | Button Menu'], // Document Frame       | 100px | 2 items
//   //divider

//   [
//     'Item Chooser           | icon btn',
//     'Item Lib Chooser       | icon btn',
//     'Color Chooser          | icon btn',
//     'Asset Chooser          | icon btn',
//   ], // Any frameType | 144px | 4 items
//   //divider
//   // TOTAL WIDTH | GRID/COLLECTION 435px | Document Frame 535px (paint+zoom | 100px)

//   ['Select                 | icon btn', 'Annotate               | menu btn', 'Promote                | icon btn'], // Any frameType
//   ['Text', 'Inset Image', 'Pen', 'Shape', 'Lines', 'Sticky Note', 'Embed'], // Document Frame      | 252px | 7 items | One Component
//   'Advanced                 | menu btn', // Any frameType | 36px
//   //divider

//   //////////////////////////////////////////////////////////////////////
//   // Current Frame is Document
//   [
//     // Text selected      | ...
//     'Option | Font | Size | Color | Formatting | Paragraph | Link | Fill | Border',
//     // Sticky selected    | ...
//     'Background | Type | Font | Size | Color | Formatting | Paragraph',
//     // Image selected     | 36px
//     'Border',
//     // Shape selected
//     'Color | Border | Round(rectangle only)',
//     // SVG Selected       | 72px
//     'Color | Border',
//     // Lines selected     | 72px
//     'Color | Thickness',
//     // Highlight selected | 72px
//     'Color | Thickness',
//     // Pen line selected  | 72px
//     'Color | Thickness',
//     // Item selected      | 130px
//     'Format options       | text btn',
//   ],
//   //////////////////////////////////////////////////////////////////////
//   ['Lineboard options    | text btn'], // Document Frame && Lineboard     | 150px
//   ['Preview              | text btn', 'Layout Options       | text btn'], // Collection Frame  | 214px (84+130) | 2 items
//   ['Add Section          | text btn', 'Layout Options       | text btn'], // Grid Frame        | 248px (118+130) | 2 items
//   'Export                   | menu btn', // Any frameType  | 75px
//   //////////////////////////////////////////////////////////////////////

//   // RIGHT SIDE -------------------------------
//   ['Dashboard              | icon btn', 'Search                 | icon btn'], // Any Frame Type      | 80px | 2 items | 8px padding
//   // RIGHT CHOOSER PANEL    | 340px + 8px extra padding
// ];
