import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SizeRangeHelper } from '@common/size-range/size-range-helper';
import { PropertyType } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import {
  FilterConditionTypeMeta,
  FilterConditionType,
  getFilterConditionTypes,
  FilterConditionTypeMetaMap,
  FilterConditionTypePropertyTypeMap,
} from '@contrail/filters';
import { FilterPropertyCriteria } from '@contrail/filters';
import {
  FilterDefinition,
  FilterOption,
  FilterPropertyDefinition,
} from 'src/app/common/types/filters/filter-definition';

@Component({
  selector: 'app-filter-details',
  templateUrl: './filter-details.component.html',
  styleUrls: ['./filter-details.component.scss'],
})
export class FilterDetailsComponent implements OnChanges, OnInit {
  @Input() filterDefinition: FilterDefinition;
  @Input() filterPropertyDefinition: FilterPropertyDefinition;
  @Input() filterPropertyCriteria: FilterPropertyCriteria;
  @Input() sourceValues: Array<any>;
  @Input() equalsOnly = false;
  @Input() propertyTypeDefaultFilterConditions: any;
  @Output() remove: EventEmitter<FilterPropertyCriteria> = new EventEmitter();
  @Output() criteriaChange: EventEmitter<FilterPropertyCriteria> = new EventEmitter();
  @ViewChild('autoCompleteField') autoCompleteField: ElementRef;
  formControl = new UntypedFormControl();
  filteredProperties: Observable<FilterPropertyDefinition[]>;
  public filterConditionTypes: Array<FilterConditionType>;
  public stringPropertyFormConfiguration: any = { isFilter: true };
  constructor() {}

  ngOnInit() {
    this.setConditionTypes();
    this.filteredProperties = this.formControl.valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.propertyLabel)),
      map((propertyLabel) =>
        propertyLabel && propertyLabel !== ''
          ? this._filter(propertyLabel)
          : this.filterDefinition?.filterPropertyDefinitions.slice(),
      ),
    );
    if (this.filterPropertyDefinition) {
      // sets the selected property from the column header menu
      const currentFilterPropertyDefinition = ObjectUtil.cloneDeep(this.filterPropertyDefinition);
      this.formControl.setValue(currentFilterPropertyDefinition);
      if (
        this.filterPropertyDefinition.propertyType === 'boolean' &&
        !this.filterPropertyCriteria.hasOwnProperty('criteriaValue')
      ) {
        // only default to false when there is no current criteria value
        this.filterPropertyCriteria.criteriaValue = false; // set the value to false by default or else the criteria will be null
      }
    }
    this.setOptionValues();
  }

  ngOnChanges(): void {
    this.setConditionTypes();
    this.setOptionValues();
  }

  private setConditionTypes() {
    if (this.filterPropertyDefinition) {
      if (this.equalsOnly) {
        this.filterConditionTypes = [FilterConditionType.EQUALS];
      } else {
        this.filterConditionTypes = getFilterConditionTypes(this.filterPropertyDefinition.propertyType);
      }
    }
  }

  private setOptionValues() {
    const objRefObjects: any = {};
    const stringOptionSet: Set<string> = new Set();
    if (!this.sourceValues) {
      return;
    }
    if (
      ![PropertyType.String, PropertyType.SizeRange, PropertyType.ObjectReference, PropertyType.UserList].includes(
        this.filterPropertyDefinition?.propertyType,
      )
    ) {
      return;
      ``;
    }

    for (const obj of this.sourceValues) {
      let val = ObjectUtil.getByPath(obj, this.filterPropertyDefinition.slug);
      if ((val === null || val === undefined) && obj.properties) {
        val = obj.properties[this.filterPropertyDefinition.slug];
      }
      if (this.filterPropertyDefinition?.propertyType === PropertyType.SizeRange) {
        if (val) {
          SizeRangeHelper.getSizeRangeSizesList(val).forEach((size) => stringOptionSet.add(size));
        }
      } else if (
        [PropertyType.ObjectReference, PropertyType.ObjectReferenceList, PropertyType.UserList].includes(
          this.filterPropertyDefinition?.propertyType,
        )
      ) {
        if (val?.id) {
          stringOptionSet.add(val.id);
          objRefObjects[val.id] = val.email || val.name;
        }
      } else {
        if (!val && obj.properties) {
          val = obj.properties[this.filterPropertyDefinition.slug];
        }
        stringOptionSet.add(val);
      }
    }

    let stringOptions: FilterOption[];
    if (
      [PropertyType.ObjectReference, PropertyType.ObjectReferenceList, PropertyType.UserList].includes(
        this.filterPropertyDefinition?.propertyType,
      )
    ) {
      stringOptions = [...stringOptionSet]
        .sort((v1, v2) => {
          return objRefObjects[v1] > objRefObjects[v2] ? 1 : -1;
        })
        .map((s) => {
          return { value: s, display: objRefObjects[s] };
        });
    } else {
      stringOptions = [...stringOptionSet]
        .sort((v1, v2) => {
          return v1 > v2 ? 1 : -1;
        })
        .map((s) => {
          return { value: s, display: s };
        });
    }

    this.stringPropertyFormConfiguration = {
      typeProperty: {
        ...this.filterPropertyCriteria?.filterPropertyDefinition,
        options: stringOptions,
      },
      isFilter: true,
    };
    if (
      this.filterPropertyDefinition?.propertyType === PropertyType.SizeRange ||
      this.filterPropertyDefinition?.propertyType === PropertyType.UserList
    ) {
      this.filterPropertyCriteria.filterPropertyDefinition.options = stringOptions;
    }
  }

  setFilterPropertyDefinition(event) {
    const filterPropertyDefinition: FilterPropertyDefinition = ObjectUtil.cloneDeep(event.option.value);
    if (filterPropertyDefinition.propertyType === PropertyType.ObjectReference) {
      filterPropertyDefinition.referencedObjName =
        filterPropertyDefinition.slug === 'itemOption' ? 'optionName' : 'name';
    }
    this.filterPropertyCriteria.filterPropertyDefinition = filterPropertyDefinition;
    this.filterPropertyCriteria.criteriaValue = null;
    if (filterPropertyDefinition.propertyType === 'boolean') {
      this.filterPropertyCriteria.criteriaValue = false; // set the value to false by default or else the criteria will be null
    }
    let filterConditionType = FilterConditionTypePropertyTypeMap.get(filterPropertyDefinition.propertyType)[0];
    if (
      this.propertyTypeDefaultFilterConditions &&
      this.propertyTypeDefaultFilterConditions[filterPropertyDefinition.propertyType] &&
      !this.equalsOnly
    ) {
      filterConditionType = this.propertyTypeDefaultFilterConditions[filterPropertyDefinition.propertyType];
    }
    this.filterPropertyCriteria.filterConditionType = filterConditionType;
  }

  setCondition(event) {
    this.filterPropertyCriteria.filterConditionType = event.value;
    // reset value for userList property
    if (
      this.filterPropertyCriteria.filterPropertyDefinition?.propertyType === PropertyType.UserList &&
      this.filterPropertyCriteria.criteriaValue &&
      ![FilterConditionType.IS_ANY_OF, FilterConditionType.IS_NONE_OF].includes(
        this.filterPropertyCriteria.filterConditionType,
      )
    ) {
      this.filterPropertyCriteria.criteriaValue = null;
    }
    this.criteriaChange.emit(this.filterPropertyCriteria);
  }

  getFilterConditionTypeMeta(filterConditionType): FilterConditionTypeMeta {
    return FilterConditionTypeMetaMap.get(filterConditionType);
  }

  removeCriteria() {
    this.remove.emit(this.filterPropertyCriteria);
  }

  handleValueChange(change) {
    console.log('FilterDetailsComponent: handleValueChange: ', change);
    this.filterPropertyCriteria.criteriaValue = change.value;
    this.criteriaChange.emit(this.filterPropertyCriteria);
  }

  clearAutoCompleteValue() {
    this.formControl.setValue('');
    setTimeout(() => {
      this.autoCompleteField.nativeElement.blur();
      this.autoCompleteField.nativeElement.focus();
    }, 100);
  }

  displayFn(property: FilterPropertyDefinition): string {
    return property && property.label ? property.label : '';
  }

  private _filter(propertyLabel: string): FilterPropertyDefinition[] {
    const filterValue = propertyLabel.toLowerCase();
    return this.filterDefinition?.filterPropertyDefinitions.filter((property) =>
      property.label.toLowerCase().includes(filterValue),
    );
  }
}
